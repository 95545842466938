export const langData = [{
    type: 'cn',
    text: '中文版本',
    lang: '中文',
    prop: {
        titleProp: 'name',
        contentProp: 'content',
        placeHolder: '请输入中文协议内容...'
    }
}, {
    type: 'en',
    text: '英文版本',
    lang: 'En',
    prop: {
        titleProp: 'name',
        contentProp: 'content_en',
        placeHolder: 'Please enter the English agreement...'
    }
}];
/**
 * 处理协议展示
 * @param {*} type 类型
 * @returns {string}
 */
export const getScene = (type) => {
    switch (type) {
        case 'privacy':
            return '隐私声明';
        case 'service':
            return '用户使用协议';
        case 'pay':
            return '付款协议';
        case 'cookies':
            return 'COOKIES协议';
        default:
            break;
    }
};
