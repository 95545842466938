<template>
    <div class="aops-aggrement-content">
        <el-upload
            ref="uploader"
            class="avatar-uploader"
            accept=".jpg, .png"
            name="img"
            action="#"
            :auto-upload="false"
            :on-change="handleUploadImage"
            :show-file-list="false"
        ></el-upload>
        <quill-editor
            ref="agQuillEditor"
            v-model="form[prop.contentProp]"
            :options="editorOption"
        ></quill-editor>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
// import api from '@/api/aggrement';
import uploadImg from '@/mixins/upload-img';

export default {
    components: {
        quillEditor
    },
    mixins: [uploadImg],
    props: {
        form: {
            type: Object,
            default: () => ({
                content: ''
            })
        },
        prop: {
            type: Object,
            default: () => ({
                contentProp: 'content',
                placeHolder: ''
            })
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {};
    },
    computed: {
        /**
         * 编辑器
         * @returns {object}
        */
        editorOption () {
            return {
                placeholder: this.prop.placeHolder,
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'],
                            ['blockquote', 'code-block'],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ script: 'sub' }, { script: 'super' }],
                            [{ indent: '-1' }, { indent: '+1' }],
                            [{ direction: 'rtl' }],
                            [{ size: ['small', false, 'large', 'huge'] }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: ['#4487dc', '#c6c6c6', '#000', '#fff'] }, { background: ['#4487dc', '#c6c6c6', '#000', '#fff'] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ['link', 'image', 'video'],
                            ['clean']
                        ],
                        handlers: {
                            image: this.handleImage
                        }
                    }
                }
            };
        }
    },
    methods: {
        /**
         * 上传图片
         * @param {File} file 文件
        */
        handleUploadImage(file) {
            // const formData = new FormData();
            // formData.append('image', file.raw);
            // api.upLoad(formData).then((res) => {
            //     const quill = this.$refs.agQuillEditor.quill;
            //     if (res.data.data) {
            //         const length = this.$refs.agQuillEditor.quill.selection.savedRange.index;
            //         quill.insertEmbed(length, 'image', res.data.data.url);
            //         quill.setSelection(length + 1);
            //     } else {
            //         this.$Message.error('Image upload error');
            //     }
            // });
            this.handleUploadImg(file).then((res) => {
                const quill = this.$refs.agQuillEditor.quill;
                const length = this.$refs.agQuillEditor.quill.selection.savedRange.index;
                quill.insertEmbed(length, 'image', res.url);
                quill.setSelection(length + 1);
            });
        },
        /**
         * 图片处理
         * @param {Boolean} value 值
        */
        handleImage(value) {
            if (value) {
                this.$refs.uploader.$refs['upload-inner'].$refs.input.click();
            } else {
                this.quill.format('image', false);
            }
        }
    }
};
</script>
