<template>
    <el-dialog
        :close-on-click-modal="false"
        :visible="dialogView"
        :before-close="handleClose"
        top="0"
        class="aops-edit-view-dialog"
        @closed="handleClosed"
    >
        <div slot="title" class="aop-dialog-title">
            <div class="aop-view-lang-switch">
                <template v-for="(item, index) in newLangData">
                    <span
                        :key="item.type"
                        class="aop-view-lang"
                        :class="{'aop-view-lang-active': curLang === item.type}"
                        @click="handleLangChange(item.type)"
                    >{{item.lang}}</span>
                    <span v-if="index === 0" :key="index">/</span>
                </template>
            </div>
        </div>
        <div class="aop-view-wrap">
            <div v-if="leftIcon" class="aop-view-left">
                <i class="iconfont aop-view-icon">&#xe67d;</i>
            </div>
            <div class="aop-view-right ql-snow">
                <div class="aop-view-title">{{ data[prop.titleProp] }}</div>
                <div class="aop-view-date">
                    {{data.time}}
                </div>
                <div v-dompurify-html="newData[prop.contentProp]" class="aop-view-content ql-editor"></div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// 消息推送预览和协议预览
export default {
    props: {
        dialogView: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => ({
                title: '',
                content: '',
                time: ''
            })
        },
        langData: {
            type: Array,
            default: () => ([])
        },
        leftIcon: {
            type: Boolean,
            default: true
        }
    },
    /**
     * data
     * @returns {object}
    */
    data() {
        return {
            curLang: 'cn'
        };
    },
    computed: {
        /**
         * 为了重置弹窗状态，重新定义了一份弹窗需要的展示数据
         * @returns {Object}
        */
        newData() {
            return this.dialogView ? this.data : {};
        },
        /**
         * 中英文数据
         * @returns {Array}
        */
        newLangData() {
            return this.langData.slice().reverse();
        },
        /**
         * 当前语言，对应字段
         * @returns {Object}
        */
        prop() {
            const child = this.langData.find((item) => item.type === this.curLang);
            return child ? child.prop : {};
        }
    },
    methods: {
        /**
         * 切换语言
         * @param {string} lang 当前语言
        */
        handleLangChange(lang) {
            this.curLang = lang;
        },
        /**
         * 关闭弹窗
        */
        handleClose() {
            this.$emit('update:dialogView', false);
        },
        /**
         * 关闭动画结束时的回调,重置curLang为中文
        */
        handleClosed() {
            this.curLang = 'cn';
        }
    }
};
</script>
<style lang="less">
@import "./viewDialog.less";
</style>
