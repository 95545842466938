<template>
  <div
    class="aops-agreement-edit"
    v-if="
      hasResource('agreement_edit') ||
      hasResource('agreement_add') ||
      hasResource('agreement_update')
    "
  >
    <div class="aop-edit" v-loading="loading">
      <div
        class="aop-back-btn text-back"
        @click="back"
      >
        <i class="iconfont fz-18 black">&#xe647;</i>
        <span class="back-text">返回</span>
      </div>
      <div class="pd-l-25">
        <div class="fz-24 fw-700 pd-t-30">编辑协议</div>
        <div class="mg-t-30">
          <el-form :inline="true" :rules="rules" ref="form" :model="form">
            <el-row :gutter="24" class>
              <el-col :span="6">
                <el-form-item label="应用端:">
                  <div>
                    {{ this.form.client === 1 ? "PBRMAX" : "INCEPTION" }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="类型:">
                  <div>
                    {{getScene(this.form.scene)}}
                  </div></el-form-item
                >
              </el-col>
              <el-col :span="6">
                <el-form-item label="市场:"
                  ><div>
                    {{ this.form.market === "CN" ? "国内市场" : "国外市场" }}
                  </div>
                </el-form-item> </el-col
              >
            </el-row>
          </el-form>
        </div>
        <div class="aop-agreement-lang">
            <span
                v-for="item in langData"
                :key="item.type"
                class="aop-lang-item"
                :class="{'aop-lang-active': item.type === curLang}"
                @click="handleLangChange(item.type)"
            >{{item.text}}</span>
        </div>
        <div v-for="item in langData" :key="item.type">
            <edit-content
                v-show="item.type === curLang"
                :form="form"
                :prop="item.prop"
            />
        </div>
        <div class="action-container pop-dialog float-right mg-t-30">
          <el-button
            @click="confirmUpdateAggrement"
            :disabled="!form.content || !form.content_en || $route.query.content === form.content"
            class="confirm-button"
            :class="{'aop-confirm-button-disabled': !form.content || !form.content_en}"
            >确 认</el-button
          >
          <el-button @click="handlePreview" class="confirm-button"
            >预 览</el-button
          >
          <el-button class="cancel-button" @click="back">取 消</el-button>
        </div>
      </div>
    </div>

    <!-- 预览弹框 -->
    <viewDialog
        :dialog-view.sync="dialogVisibleRelease"
        :data="{...form, time:nowTime}"
        :langData="langData"
        :leftIcon="false"
    />
  </div>
</template>

<script>
import api from "../../../api/aggrement";
import { quillEditor } from "vue-quill-editor";
import viewDialog from '@/pages/_common/viewDialog/viewDialog.vue';
import editContent from './editContent.vue'
import aggrement from "../../../mixins/aggrement";
import { langData, getScene } from './config'
export default {
  components: {
    quillEditor,
    viewDialog,
    editContent
  },
  mixins: [aggrement],
  data() {
    return {
      curLang: 'cn',
      langData,
      form: {
        // language: "",
        scene: "",
        market: "",
        content: "",
        content_en: "",
        client: "",
      },
      nowTime: "",
      scene: {},
      header: {
        url: "agreementManagement",
      },
      market: "",
      editAggrementId: "",
      dialogVisibleRelease: false,
      loading: false,
    };
  },
  computed: {
    editorOption: function () {
      return {
        placeholder:
          this.$route.query.language === "zh-CN"
            ? "请输入中文协议内容..."
            : "Please enter the English agreement...",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: ['#4487dc', '#c6c6c6', '#000', '#fff']}, { background: ['#4487dc', '#c6c6c6', '#000', '#fff']} ],
              [{ font: [] }],
              [{ align: [] }],
              ["link", "image", "video"],
              ["clean"],
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      };
    },
  },
  methods: {
    init() {
      this.getCurrentAggrementId();
      this.getCurrentAggrementInfo();
      this.formDate();
    },
    formDate() {
      var date = new Date();
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.nowTime = YY + MM + DD;
    },
    createAggrement() {
      this.validForm(this.createAggrementConfirm);
    },
    createAggrementConfirm() {
      let params = {
        name: this.form.name,
        market: this.form.market,
        content: this.form.content,
        scene: this.form.scene,
      };
      this.loading = true;
      api.aggrementCreate(params).then((res) => {
        if (res.data.code === 10200) {
          this.back();
        } else {
          this.$$error(res.data.message);
        }
        this.loading = false;
      });
    },
    confirmUpdateAggrement() {
      this.validForm(this.confirmUpdateAggrementConfirm);
    },
    confirmUpdateAggrementConfirm() {
      //todo 表单验证
      let params = {
        id: this.form.id,
        // name: this.form.name,
        market: this.form.market,
        content: this.form.content,
        content_en: this.form.content_en,
        scene: this.form.scene,
        // update: this.form.status,
      };
      if (!this.form.content) {
        this.$$error("请输入协议内容");
        return;
      }
      this.loading = true;
      api.aggrementUpdate(params).then((res) => {
        if (res.data.code === 10200) {
          this.$$success('编辑成功!');
          this.back();
        } else {
          this.$$error(res.data.message);
        }
        this.loading = false;
      });
    },
    back() {
      this.$router.push("/settings/agreements");
      this.msgBus.$emit("agreementList");
    },
    getCurrentAggrementId() {
      let query = this.$route.query;
      this.editAggrementId = query.id;
    },
    getCurrentAggrementInfo() {
      let editAggrementId = this.editAggrementId;
      if (!editAggrementId) {
        return;
      }
      this.loading = true;
      api.aggrementInfo(editAggrementId).then((res) => {
        if (res.data.code === 10200) {
          this.form = res.data.data;
        } else {
          this.$$error(res.data.message);
        }
        this.loading = false;
      });
    },
    handlePreview() {
        this.dialogVisibleRelease = true;
    },
    // handleImage(file) {
    //   let formData = new FormData();
    //   formData.append("image", file.raw);
    //   api.upLoad(formData).then((res) => {
    //     let quill = this.$refs.agQuillEditor.quill;
    //     if (res.data.data) {
    //       if (res.data.data.length > 0) {
    //         let length =
    //           this.$refs.agQuillEditor.quill.selection.savedRange.index;
    //         quill.insertEmbed(length, "image", res.data.data.url);
    //         quill.setSelection(length + 1);
    //       }
    //     } else {
    //       this.$Message.error("Image upload error");
    //     }
    //   });
    // },
     /**
    * 获取协议类型
    */
    getScene(type) {
        return getScene(type);
    },
    /**
     * 切换语言
    */
    handleLangChange(lang) {
        this.curLang = lang;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
@import "scss/aggrementEdit.less";
</style>
