import axios from '../uitls/require'

export default {
  agreementScene() {
    return axios.get('/admin/agreement/scene')
  },
  aggrementCreate(params) {
    return axios.post('/admin/agreement/create' , params)
  },
  aggrementUpdate(params) {
    return axios.post('/admin/agreement/update' , params)
  },
  agreementRelease(params) {
    return axios.post('/admin/agreement/release' , params)
  },
  agreementList(params) {
    return axios.post('/admin/agreement/list' , params)
  },
  aggrementInfo(id) {
    return axios.get('/admin/agreement?id=' + id)
  },
  aggrementDelete(id) {
    return axios.get('/admin/agreement/delete?id=' + id)
  },
//   upLoad(data) {
//     return axios.post('/admin/agreement/upload' ,data)
//   }

}
